import React, { FunctionComponent, ReactNode } from "react"
import { Box } from "@chakra-ui/react"
import { useColor } from "../../shared/hooks/use-color.hook"

interface Props {
  children: ReactNode
}

export const Layout: FunctionComponent<Props> = ({ children }) => {
  const colors = useColor()

  // const loadingIndicator = useRecoilValue(loadingIndicatorState)
  return (
    <Box bg={colors.mainBackground} w={"100%"} h={"100%"}>
      {/* <Box w='100%' h={12} position='fixed' zIndex={200}>
        {loadingIndicator && (
          <Progress
            size='sm'
            isIndeterminate
            colorScheme={colors.primaryColor}
          />
        )}
        <NavBar />
      </Box> */}

      <Box minH={"100vh"} zIndex={100}>
        {children}
      </Box>
    </Box>
  )
}

export default Layout
